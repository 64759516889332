import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Table,
  Button,
  Image,
  Segment,
  Header,
  Icon,
  Checkbox,
  Confirm,
  Message,
  Modal,
} from "semantic-ui-react";
import EndPointDetailsForm from "./EndPointDetailsForm";
import * as YAML from "yaml";
import CodeMirror from "@uiw/react-codemirror";
import _ from "lodash";
import {
  fetchAllModuleNames,
  fetchEndpointDetail,
  fetchEndpointDetails,
  submitCreateConfig,
  submitPromoteConfig,
} from "services/self-service";
import { useForm } from "react-hook-form";
import { store } from "services/state";

function APIGWSelfService() {
  const {
    register,
    unregister,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const dev = process.env.REACT_APP_ENVIRONMENT_NAME === "dev";
  const [displayForm, setDisplayForm] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [apitype, setApitype] = useState(null);
  const [moduleOptions, setModuleoptions] = useState({});
  const [module, setModule] = useState(null);
  const [api, setApi] = useState("");
  const [userAction, setUserAction] = useState("");
  const [apis, setApis] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [editedEndpoints, setEditedEndpoints] = useState([]);
  const [createdEndpoints, setCreatedEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState({});
  const [config, setConfig] = useState(null);
  const [isSingleUploader, setIsSingleUploader] = useState(false);
  const [response, setResponse] = useState({});

  const changeApitype = async (apitype) => {
    if (editedEndpoints.length === 0) {
      setDisplayForm(true);
      setApitype(apitype);
      const modules = await fetchAllModuleNames(apitype);
      setModuleoptions(modules);
      setModule(null);
      setApi("");
    }
  };

  const changeModule = (value) => {
    setModule(value);
    setApi("");
    setApis(moduleOptions[value]);
    setEditedEndpoints([]);
    setSelectedEndpoint({});
  };

  const changeAPI = async (apiName) => {
    setApi(apiName);
    const endpoints = await fetchEndpointDetails(apitype, module, apiName);
    setEndpoints(
      endpoints.endPointDetails.map((endpoint, index) => endpoint[index])
    );
    setEditedEndpoints([]);
    setSelectedEndpoint({});
  };

  const viewEndpoint = async (endpoint) => {
    if (dev) {
      setSelectedEndpoint(endpoint);
      setActionsModal(true);
      setUserAction("view");
    } else {
      try {
        const params = {
          moduleName: module,
          apiName: api,
          userInputFile: "configYaml",
          resourcePath: endpoint.resourcePath,
          resourceVersion: endpoint.resourceVersion,
          httpMethod: endpoint.backendDetails.httpMethod,
        };
        const response = await fetchEndpointDetail(apitype, params);
        if (response.error) {
          setErrorModal(true);
        } else {
          setSelectedEndpoint(response.data);
          setActionsModal(true);
          setUserAction("view");
        }
      } catch (e) {
        setErrorModal(true);
      }
    }
  };

  const promoteEndpoint = (endpoint) => {
    setEditedEndpoints(
      editedEndpoints.concat({ ...endpoint, userAction: "promote" })
    );
  };

  const handleCheckBox = (endpoint, isChecked) => {
    if (isChecked) {
      promoteEndpoint(endpoint);
    } else {
      clearEndpoint(endpoint);
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setEditedEndpoints(
        endpoints.map((endpoint) => ({
          ...endpoint,
          userAction: "promote",
        }))
      );
    } else {
      setEditedEndpoints([]);
    }
  };

  const createEndpoint = () => {
    if (apitype === "rest") {
      setSelectedEndpoint(DEFAULT_REST_CONFIG);
      setUserAction("create");
      setActionsModal(true);
    } else {
      setSelectedEndpoint(DEFAULT_WEBSOCKET_CONFIG);
      setUserAction("create");
      setActionsModal(true);
    }
  };

  const updateEndpoint = (endpoint) => {
    setSelectedEndpoint({ ...endpoint, operation: "create" });
    setUserAction("edit");
    setActionsModal(true);
  };

  //Edit the newly created endpoint before building the pipeline
  const editNewEndpoint = (endpoint) => {
    setSelectedEndpoint(endpoint);
    setUserAction("create");
    setActionsModal(true);
  };

  const getEditedEndpoint = (endpoint) => {
    return editedEndpoints.find(
      (endPointDetails) =>
        generateEndpointName(endPointDetails) === generateEndpointName(endpoint)
    );
  };

  const clearEndpoint = (endpoint) => {
    unregister(generateEndpointName(endpoint));
    setEditedEndpoints(
      editedEndpoints.filter(
        (endPointDetails) =>
          generateEndpointName(endPointDetails) !==
          generateEndpointName(endpoint)
      )
    );
  };

  const handleFile = (event, name) => {
    setValue(name, event.target.files[0], { shouldValidate: true });
    const fileReader = new FileReader();
    const file = event.target.files[0];
    if (validateFile(file)) {
      fileReader.onload = (event) => {
        const content = event.target.result;
        const json =
          file.type === "application/json"
            ? JSON.parse(content)
            : YAML.parse(content);

        if (name === "singleFile") {
          setEditedEndpoints(
            editedEndpoints.map((endpoint) => ({
              ...endpoint,
              specification: {
                file: JSON.stringify(json),
                fileType: file.name.split(".").pop(),
              },
            }))
          );
        } else {
          setEditedEndpoints(
            editedEndpoints.map((endpoint) => {
              if (generateEndpointName(endpoint) === name) {
                return {
                  ...endpoint,
                  specification: {
                    ...endpoint.specification,
                    file: JSON.stringify(json),
                    fileType: file.name.split(".").pop(),
                  },
                };
              } else {
                return endpoint;
              }
            })
          );
        }
      };
      fileReader.readAsText(file);
    }
  };

  const checkIfEdited = (endpoint) => {
    if (!editedEndpoints.length) {
      return false;
    }

    return editedEndpoints.some(
      (endPointDetails) =>
        generateEndpointName(endPointDetails) === generateEndpointName(endpoint)
    );
  };

  useEffect(() => {
    //Comparing the edited endpoints with existing ones to check if the endpoint is really edited or not
    editedEndpoints.map((endPointDetails) =>
      // eslint-disable-next-line array-callback-return
      endpoints.map((endpoint) => {
        if (_.isEqual(endpoint, _.omit(endPointDetails, "specification"))) {
          setEditedEndpoints(
            editedEndpoints.filter(
              (endPointDetail) =>
                !(
                  generateEndpointName(endPointDetail) ===
                  generateEndpointName(endpoint)
                )
            )
          );
        }
      })
    );

    //To display the created endpoint
    setCreatedEndpoints(
      editedEndpoints.filter((endpoint) => endpoint.userAction === "create")
    );

    //Check if a single swagger file is needed
    const checkIfSingleFile = () => {
      if (apitype === "websocket") return false;
      if (editedEndpoints.length < 2) return false;

      //Check if all the endpoints are based on lambda or lambda_proxy integration
      const isLambdaIntegration = editedEndpoints.every((endpoint) =>
        /lambda/i.test(endpoint.integrationType)
      );

      //Check if all the inputs have the same lambda ARN
      const isSameArn = () => {
        return editedEndpoints.every(
          (endpoint) =>
            endpoint.backendDetails.uri ===
            editedEndpoints[0].backendDetails.uri
        );
      };

      //Check if all the endpoints are based in the same integration type
      const isSameIntegrationType = () => {
        return editedEndpoints.every(
          (endpoint) =>
            endpoint.integrationType === editedEndpoints[0].integrationType
        );
      };

      //Get the basepath of the HTTPS URI along with the domain and port
      const getBasePath = (uri) => {
        const url = new URL(uri);
        return url.host.concat(url.pathname.split("/")[1]);
      };

      if (isLambdaIntegration) {
        return isSameArn();
      } else if (isSameIntegrationType()) {
        return editedEndpoints.every(
          (endpoint) =>
            getBasePath(endpoint.backendDetails.uri) ===
            getBasePath(editedEndpoints[0].backendDetails.uri)
        );
      } else {
        return false;
      }
    };

    setIsSingleUploader(checkIfSingleFile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedEndpoints, endpoints, apitype]);

  useEffect(() => {
    if (!isSingleUploader) {
      unregister("singleFile"); //Unregistering the single file input field from react hook form
    } else {
      const allFields = Object.keys(getValues());
      unregister(allFields.filter((field) => field !== "singleFile")); //Unregistering all the file input fields except the single file input field
    }
  }, [isSingleUploader, unregister, getValues]);

  useEffect(() => {
    const endPointDetails = editedEndpoints.map((endpoint, index) => ({
      [index]: _.omit(endpoint, ["userAction", "specification"]),
    }));

    const config = {
      version: "1.0.0",
      kind: "api_gw_selfservice",
      apitype: apitype,
      moduleName: module,
      name: api,
      endPointDetails: endPointDetails,
    };
    YAML.stringify(config);
    setConfig(config);
  }, [api, apitype, editedEndpoints, endpoints, module]);

  const generateEndpointName = (endpoint) => {
    if (apitype === "rest") {
      return (
        endpoint.backendDetails.httpMethod +
        "/" +
        endpoint.resourceVersion +
        endpoint.resourcePath
      );
    } else if (apitype === "websocket") return endpoint.action;
  };

  const validateFile = (file) => {
    const allowedFileFormats = ["json", "yml", "yaml"];
    try {
      const fileFormat = file.name.split(".").pop();
      return allowedFileFormats.includes(fileFormat);
    } catch (e) {
      return false;
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  };

  const isTriggered = (endpoint, response) => {
    return response.some(
      (endpointResponse) =>
        endpointResponse.endpointName === generateEndpointName(endpoint) &&
        isValidUrl(endpointResponse.message)
    );
  };

  const submitInputs = async () => {
    const endPointDetails = editedEndpoints.map((endpoint, index) => ({
      [index]: _.omit(endpoint, "userAction"),
    }));

    const createConfig = {
      version: "1.0.0",
      kind: "api_gw_selfservice",
      apitype: apitype,
      moduleName: module,
      name: api,
      endPointDetails: endPointDetails,
      userIdentity: store.user?.email,
    };

    const promoteConfig =
      apitype === "rest"
        ? editedEndpoints
            .filter((endpoint) => endpoint.userAction === "promote")
            .map((endpoint) => ({
              moduleName: module,
              apiName: api,
              resourcePath: endpoint.resourcePath,
              resourceVersion: endpoint.resourceVersion,
              httpMethod: endpoint.backendDetails.httpMethod,
              userIdentity: store.user?.email,
            }))
        : editedEndpoints
            .filter((endpoint) => endpoint.userAction === "promote")
            .map((endpoint) => ({
              moduleName: module,
              apiName: api,
              action: endpoint.action,
              userIdentity: store.user?.email,
            }));

    //Send the config to the backend
    const response = dev
      ? await submitCreateConfig(apitype, createConfig)
      : await submitPromoteConfig(apitype, promoteConfig);

    if (!response.error) {
      //The newly created endpoints for which the Jenkins pipelines are successfully triggered will be added to the existing endpoints
      setEndpoints([
        ...endpoints,
        ...createdEndpoints.filter((endpoint) =>
          isTriggered(endpoint, response.message)
        ),
      ]);

      //The endpoints for which the Jenkins pipelines are successfully triggered will stay in the inputs
      setEditedEndpoints(
        editedEndpoints.filter(
          (endpoint) => !isTriggered(endpoint, response.message)
        )
      );
    }
    setResponse(response);
    setConfirmBox(false);
  };

  const cards = (
    <Card.Group>
      <Card
        data-testid="card"
        style={{ background: `${apitype === "rest" ? "#eb9b34" : "white"}` }}
        raised={apitype === "rest"}
        onClick={() => changeApitype("rest")}
      >
        <Card.Content>
          <Card.Header>REST API</Card.Header>
          <Card.Description textAlign="center">
            <Image
              width="50"
              height="50"
              centered
              src="/custom-content/openapi-logo.png"
            />
          </Card.Description>
          <Card.Description textAlign="center">
            Click here to view, create, update or delete the REST API endpoints
          </Card.Description>
        </Card.Content>
      </Card>
      <Card
        data-testid="card"
        style={{
          background: `${apitype === "websocket" ? "#eb9b34" : "white"}`,
        }}
        raised={apitype === "websocket"}
        onClick={() => changeApitype("websocket")}
      >
        <Card.Content>
          <Card.Header>WebSocket API</Card.Header>
          <Card.Description textAlign="center">
            <Image
              width="50"
              height="50"
              centered
              src="/custom-content/async-api-logo.png"
            />
          </Card.Description>
          <Card.Description textAlign="center">
            Click here to view, create, update or delete the WebSocket API
            endpoints
          </Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
  );

  const fileUploader = (name) => (
    <Form.Input
      label="API specification file"
      refs={register(name, {
        required: "Please upload the API specification file",
        validate: (file) =>
          validateFile(file) || "File should be either json or yaml",
      })}
      type="file"
      accept="application/json,.yaml,.yml"
      onChange={(event) => handleFile(event, name)}
      error={errors[name] ? { content: errors[name].message } : false}
      placeholder="Upload file"
    ></Form.Input>
  );

  const getBuildNumber = (url) => {
    const split = url.split("/");
    return split[split.length - 1];
  };

  const getJenkinsResponse = (endpoint) => {
    const endpointResponse = response.message.find(
      (res) => res.endpointName === generateEndpointName(endpoint)
    );
    if (endpointResponse) {
      return isValidUrl(endpointResponse.message) ? (
        <Button color="blue" href={endpointResponse.message} target="blank">
          {`Build #${getBuildNumber(endpointResponse.message)}`}
        </Button>
      ) : (
        <>
          <Icon name="warning sign" /> {endpointResponse.message}
        </>
      );
    }
  };

  const apiOptions = apis.map((api, index) => ({
    key: index,
    text: api,
    value: api,
  }));

  return (
    <Container style={{ padding: "2em", width: "100%" }}>
      <Segment>
        <Header>API GW Self-Service</Header>
        <Header.Subheader>
          This is to enable modules to create and modify API endpoints on AWS
          API Gateway independently without PR approval requirement.
        </Header.Subheader>
      </Segment>
      <Segment>
        <Header>1. Select the API type</Header>
        {cards}
      </Segment>
      {displayForm && (
        <Form data-testid="form" error={response.error}>
          <Segment>
            <Header>2. Select the module name and API name</Header>
            <Form.Group style={{ padding: "2em 0" }}>
              <Form.Select
                id="html"
                name="moduleName"
                label="Module name"
                placeholder="Select Module name"
                value={module}
                search
                options={Object.keys(moduleOptions).map((module, index) => ({
                  key: index,
                  text: module,
                  value: module,
                }))}
                disabled={editedEndpoints.length > 0}
                onChange={(e, { value }) => changeModule(value)}
              />
              <Form.Select
                id="html"
                name="name"
                label="API name"
                placeholder="Select API name"
                value={api}
                search
                options={apiOptions}
                disabled={editedEndpoints.length > 0}
                onChange={(e, { value }) => changeAPI(value)}
              />
            </Form.Group>
          </Segment>
          {api !== "" && (
            <Segment>
              <Header>
                {dev
                  ? "3. Click on the required action"
                  : "3. Select the endpoint(s) to promote"}
              </Header>
              <Table data-testid="table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "3%" }}>
                      <Checkbox
                        data-testid="select-all-checkbox"
                        disabled={dev}
                        onChange={(e, { checked }) => handleSelectAll(checked)}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>Endpoint name</Table.HeaderCell>
                    <Table.HeaderCell>HTTP Method</Table.HeaderCell>
                    {apitype === "rest" && (
                      <Table.HeaderCell>Endpoint version</Table.HeaderCell>
                    )}
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    {!isSingleUploader && editedEndpoints.length > 0 && dev && (
                      <Table.HeaderCell>API specification</Table.HeaderCell>
                    )}
                    {_.isArray(response.message) && (
                      <Table.HeaderCell>Jenkins Response</Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {endpoints.map((endpoint, index) => (
                    <Table.Row
                      key={index}
                      style={{
                        background: `${
                          checkIfEdited(endpoint) ? "#fcdf03" : "white"
                        }`,
                      }}
                    >
                      <Table.Cell style={{ width: "3%" }}>
                        <Checkbox
                          disabled={dev && !checkIfEdited(endpoint)}
                          checked={editedEndpoints.some(
                            (endPointDetails) =>
                              generateEndpointName(endPointDetails) ===
                              generateEndpointName(endpoint)
                          )}
                          onChange={(e, { checked }) =>
                            handleCheckBox(endpoint, checked)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.action || endpoint.resourcePath}
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.backendDetails.httpMethod}
                      </Table.Cell>
                      {apitype === "rest" && (
                        <Table.Cell>{endpoint.resourceVersion}</Table.Cell>
                      )}
                      <Table.Cell data-testid="actions cell" singleLine>
                        <Button
                          name="View"
                          positive
                          icon="eye"
                          role="view"
                          onClick={() => viewEndpoint(endpoint)}
                        ></Button>
                        {dev && (
                          <>
                            <Button
                              color="blue"
                              icon="edit"
                              role="edit"
                              onClick={() =>
                                updateEndpoint(
                                  checkIfEdited(endpoint)
                                    ? getEditedEndpoint(endpoint)
                                    : endpoint
                                )
                              }
                            ></Button>
                            <Button
                              negative
                              icon="trash"
                              role="delete"
                            ></Button>
                          </>
                        )}
                        {checkIfEdited(endpoint) && (
                          <Button
                            color="red"
                            icon="cancel"
                            role="discard"
                            onClick={() => clearEndpoint(endpoint)}
                          />
                        )}
                      </Table.Cell>
                      {!isSingleUploader && editedEndpoints.length > 0 && (
                        <Table.Cell>
                          {checkIfEdited(endpoint) && dev
                            ? fileUploader(generateEndpointName(endpoint))
                            : "-"}
                        </Table.Cell>
                      )}
                      {_.isArray(response.message) && (
                        <Table.Cell>{getJenkinsResponse(endpoint)}</Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                  {createdEndpoints.map((endpoint, index) => (
                    <Table.Row key={index} style={{ background: "#05b025" }}>
                      <Table.Cell style={{ width: "3%" }}>
                        <Checkbox
                          checked={editedEndpoints.some(
                            (endPointDetails) =>
                              generateEndpointName(endPointDetails) ===
                              generateEndpointName(endpoint)
                          )}
                          onChange={(e, { checked }) =>
                            handleCheckBox(endpoint, checked)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.resourcePath || endpoint.action}
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.backendDetails.httpMethod}
                      </Table.Cell>
                      {apitype === "rest" && (
                        <Table.Cell>
                          {endpoint.resourceVersion || "v1"}
                        </Table.Cell>
                      )}
                      <Table.Cell data-testid="actions cell" singleLine>
                        <Button
                          name="View"
                          positive
                          icon="eye"
                          role="view"
                          onClick={() => viewEndpoint(endpoint)}
                        ></Button>
                        <Button
                          color="blue"
                          icon="edit"
                          role="edit"
                          onClick={() => editNewEndpoint(endpoint)}
                        ></Button>
                        <Button
                          negative
                          role="delete"
                          icon="trash"
                          disabled
                        ></Button>
                        <Button
                          color="red"
                          icon="cancel"
                          role="discard"
                          onClick={() => clearEndpoint(endpoint)}
                        />
                      </Table.Cell>
                      {!isSingleUploader && dev && (
                        <Table.Cell>
                          {fileUploader(generateEndpointName(endpoint))}
                        </Table.Cell>
                      )}
                      {_.isArray(response.message) && (
                        <Table.Cell>{getJenkinsResponse(endpoint)}</Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {dev && (
                <Button role="create" onClick={() => createEndpoint()}>
                  + Create new endpoint
                </Button>
              )}
              {actionsModal && (
                <EndPointDetailsForm
                  actionsModal={actionsModal}
                  setActionsModal={setActionsModal}
                  apiType={apitype}
                  existingEndpoints={endpoints}
                  selectedEndpoint={selectedEndpoint}
                  userAction={userAction}
                  editedEndpoints={editedEndpoints}
                  setEditedEndpoints={setEditedEndpoints}
                  generateEndpointName={generateEndpointName}
                />
              )}
            </Segment>
          )}
          {isSingleUploader && dev && (
            <Segment>
              <Header>4. Upload the API specification file</Header>
              {fileUploader("singleFile")}
            </Segment>
          )}
          {response.error && (
            <Message
              error={response.error}
              header={response.message}
              onDismiss={() => setResponse({})}
              data-testid="validationMessage"
            />
          )}
          {editedEndpoints.length > 0 && (
            <Segment>
              <Header>
                {`${
                  isSingleUploader && dev ? "5" : "4"
                }. Build the SSv3 Jenkins Pipeline`}
              </Header>
              <Form.Group>
                <Form.Button
                  icon
                  labelPosition="left"
                  disabled={editedEndpoints.length === 0}
                  color="green"
                  onClick={handleSubmit(() => setConfirmBox(true))}
                >
                  <Icon name="play" />
                  Build Now
                </Form.Button>
              </Form.Group>
            </Segment>
          )}
        </Form>
      )}
      {editedEndpoints.length > 0 && dev && (
        <Segment>
          <Header>API Endpoint Configuration Details</Header>
          To manually build the SSv3 Jenkins pipeline with build parameters,
          please copy the generated configuration YAML file
          {editedEndpoints.length > 0 && (
            <Form.Button
              onClick={() =>
                navigator.clipboard.writeText(YAML.stringify(config, null, 1))
              }
            >
              Copy
            </Form.Button>
          )}
          <CodeMirror
            style={{ marginTop: "10px" }}
            theme={"dark"}
            width="100%"
            maxWidth="100%"
            height="500px"
            value={YAML.stringify(config, { options: { simpleKeys: true } })}
            editable={false}
          />
        </Segment>
      )}
      <Confirm
        open={confirmBox}
        content="Are you sure you want to build the Self Service Jenkins pipeline?"
        cancelButton="Back"
        onConfirm={submitInputs}
        onCancel={() => setConfirmBox(false)}
      />
      <Modal open={errorModal}>
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>No data available for this endpoint</Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setErrorModal(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default APIGWSelfService;

const DEFAULT_REST_CONFIG = {
  integrationType: "",
  operation: "create",
  resourcePath: "",
  resourceVersion: "v1",
  productCode: "",
  backendDetails: {
    uri: "",
    httpMethod: "",
  },
  authorizationDetails: {},
  userAction: "create",
};

const DEFAULT_WEBSOCKET_CONFIG = {
  integrationType: "https-private",
  operation: "create",
  action: "",
  productCode: "",
  backendDetails: {
    uri: "",
    httpMethod: "",
  },
  authorizationDetails: {},
  userAction: "create",
};
